// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-it-jsx": () => import("./../../src/pages/index.it.jsx" /* webpackChunkName: "component---src-pages-index-it-jsx" */),
  "component---src-pages-risultati-ricerca-en-jsx": () => import("./../../src/pages/risultati-ricerca.en.jsx" /* webpackChunkName: "component---src-pages-risultati-ricerca-en-jsx" */),
  "component---src-pages-risultati-ricerca-it-jsx": () => import("./../../src/pages/risultati-ricerca.it.jsx" /* webpackChunkName: "component---src-pages-risultati-ricerca-it-jsx" */),
  "component---src-pages-rss-en-jsx": () => import("./../../src/pages/rss.en.jsx" /* webpackChunkName: "component---src-pages-rss-en-jsx" */),
  "component---src-pages-rss-it-jsx": () => import("./../../src/pages/rss.it.jsx" /* webpackChunkName: "component---src-pages-rss-it-jsx" */),
  "component---src-pages-sitemap-en-jsx": () => import("./../../src/pages/sitemap.en.jsx" /* webpackChunkName: "component---src-pages-sitemap-en-jsx" */),
  "component---src-pages-sitemap-it-jsx": () => import("./../../src/pages/sitemap.it.jsx" /* webpackChunkName: "component---src-pages-sitemap-it-jsx" */),
  "component---src-templates-approfondimento-template-en-jsx": () => import("./../../src/templates/approfondimento.template.en.jsx" /* webpackChunkName: "component---src-templates-approfondimento-template-en-jsx" */),
  "component---src-templates-approfondimento-template-it-jsx": () => import("./../../src/templates/approfondimento.template.it.jsx" /* webpackChunkName: "component---src-templates-approfondimento-template-it-jsx" */),
  "component---src-templates-bando-di-gara-template-en-jsx": () => import("./../../src/templates/bando-di-gara.template.en.jsx" /* webpackChunkName: "component---src-templates-bando-di-gara-template-en-jsx" */),
  "component---src-templates-bando-di-gara-template-it-jsx": () => import("./../../src/templates/bando-di-gara.template.it.jsx" /* webpackChunkName: "component---src-templates-bando-di-gara-template-it-jsx" */),
  "component---src-templates-comunicato-stampa-template-en-jsx": () => import("./../../src/templates/comunicato-stampa.template.en.jsx" /* webpackChunkName: "component---src-templates-comunicato-stampa-template-en-jsx" */),
  "component---src-templates-comunicato-stampa-template-it-jsx": () => import("./../../src/templates/comunicato-stampa.template.it.jsx" /* webpackChunkName: "component---src-templates-comunicato-stampa-template-it-jsx" */),
  "component---src-templates-elenchi-elenco-approfondimenti-en-jsx": () => import("./../../src/templates/elenchi/elenco_approfondimenti.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-approfondimenti-en-jsx" */),
  "component---src-templates-elenchi-elenco-approfondimenti-it-jsx": () => import("./../../src/templates/elenchi/elenco_approfondimenti.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-approfondimenti-it-jsx" */),
  "component---src-templates-elenchi-elenco-attualita-en-jsx": () => import("./../../src/templates/elenchi/elenco_attualita.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-attualita-en-jsx" */),
  "component---src-templates-elenchi-elenco-attualita-it-jsx": () => import("./../../src/templates/elenchi/elenco_attualita.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-attualita-it-jsx" */),
  "component---src-templates-elenchi-elenco-bandi-di-gara-e-contratti-en-jsx": () => import("./../../src/templates/elenchi/elenco_bandi_di_gara_e_contratti.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-bandi-di-gara-e-contratti-en-jsx" */),
  "component---src-templates-elenchi-elenco-bandi-di-gara-e-contratti-it-jsx": () => import("./../../src/templates/elenchi/elenco_bandi_di_gara_e_contratti.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-bandi-di-gara-e-contratti-it-jsx" */),
  "component---src-templates-elenchi-elenco-foto-video-en-jsx": () => import("./../../src/templates/elenchi/elenco_foto_video.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-foto-video-en-jsx" */),
  "component---src-templates-elenchi-elenco-foto-video-it-jsx": () => import("./../../src/templates/elenchi/elenco_foto_video.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-foto-video-it-jsx" */),
  "component---src-templates-elenchi-elenco-normative-en-jsx": () => import("./../../src/templates/elenchi/elenco_normative.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-normative-en-jsx" */),
  "component---src-templates-elenchi-elenco-normative-it-jsx": () => import("./../../src/templates/elenchi/elenco_normative.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-normative-it-jsx" */),
  "component---src-templates-elenchi-elenco-podcast-en-jsx": () => import("./../../src/templates/elenchi/elenco_podcast.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-podcast-en-jsx" */),
  "component---src-templates-elenchi-elenco-podcast-it-jsx": () => import("./../../src/templates/elenchi/elenco_podcast.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-podcast-it-jsx" */),
  "component---src-templates-elenchi-elenco-pubblicazioni-en-jsx": () => import("./../../src/templates/elenchi/elenco_pubblicazioni.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-pubblicazioni-en-jsx" */),
  "component---src-templates-elenchi-elenco-pubblicazioni-it-jsx": () => import("./../../src/templates/elenchi/elenco_pubblicazioni.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-elenco-pubblicazioni-it-jsx" */),
  "component---src-templates-elenchi-glossario-en-jsx": () => import("./../../src/templates/elenchi/glossario.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-glossario-en-jsx" */),
  "component---src-templates-elenchi-glossario-it-jsx": () => import("./../../src/templates/elenchi/glossario.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-glossario-it-jsx" */),
  "component---src-templates-elenchi-timeline-en-jsx": () => import("./../../src/templates/elenchi/timeline.en.jsx" /* webpackChunkName: "component---src-templates-elenchi-timeline-en-jsx" */),
  "component---src-templates-elenchi-timeline-it-jsx": () => import("./../../src/templates/elenchi/timeline.it.jsx" /* webpackChunkName: "component---src-templates-elenchi-timeline-it-jsx" */),
  "component---src-templates-galleria-flickr-template-en-jsx": () => import("./../../src/templates/galleria-flickr.template.en.jsx" /* webpackChunkName: "component---src-templates-galleria-flickr-template-en-jsx" */),
  "component---src-templates-galleria-flickr-template-it-jsx": () => import("./../../src/templates/galleria-flickr.template.it.jsx" /* webpackChunkName: "component---src-templates-galleria-flickr-template-it-jsx" */),
  "component---src-templates-galleria-video-template-en-jsx": () => import("./../../src/templates/galleria-video.template.en.jsx" /* webpackChunkName: "component---src-templates-galleria-video-template-en-jsx" */),
  "component---src-templates-galleria-video-template-it-jsx": () => import("./../../src/templates/galleria-video.template.it.jsx" /* webpackChunkName: "component---src-templates-galleria-video-template-it-jsx" */),
  "component---src-templates-normativa-template-en-jsx": () => import("./../../src/templates/normativa.template.en.jsx" /* webpackChunkName: "component---src-templates-normativa-template-en-jsx" */),
  "component---src-templates-normativa-template-it-jsx": () => import("./../../src/templates/normativa.template.it.jsx" /* webpackChunkName: "component---src-templates-normativa-template-it-jsx" */),
  "component---src-templates-notizia-template-en-jsx": () => import("./../../src/templates/notizia.template.en.jsx" /* webpackChunkName: "component---src-templates-notizia-template-en-jsx" */),
  "component---src-templates-notizia-template-it-jsx": () => import("./../../src/templates/notizia.template.it.jsx" /* webpackChunkName: "component---src-templates-notizia-template-it-jsx" */),
  "component---src-templates-pagina-foglia-completa-template-en-jsx": () => import("./../../src/templates/pagina-foglia-completa.template.en.jsx" /* webpackChunkName: "component---src-templates-pagina-foglia-completa-template-en-jsx" */),
  "component---src-templates-pagina-foglia-completa-template-it-jsx": () => import("./../../src/templates/pagina-foglia-completa.template.it.jsx" /* webpackChunkName: "component---src-templates-pagina-foglia-completa-template-it-jsx" */),
  "component---src-templates-pubblicazione-template-en-jsx": () => import("./../../src/templates/pubblicazione.template.en.jsx" /* webpackChunkName: "component---src-templates-pubblicazione-template-en-jsx" */),
  "component---src-templates-pubblicazione-template-it-jsx": () => import("./../../src/templates/pubblicazione.template.it.jsx" /* webpackChunkName: "component---src-templates-pubblicazione-template-it-jsx" */),
  "component---src-templates-sezione-con-anticipazioni-template-en-jsx": () => import("./../../src/templates/sezione-con-anticipazioni.template.en.jsx" /* webpackChunkName: "component---src-templates-sezione-con-anticipazioni-template-en-jsx" */),
  "component---src-templates-sezione-con-anticipazioni-template-it-jsx": () => import("./../../src/templates/sezione-con-anticipazioni.template.it.jsx" /* webpackChunkName: "component---src-templates-sezione-con-anticipazioni-template-it-jsx" */),
  "component---src-templates-sezione-senza-anticipazioni-template-en-jsx": () => import("./../../src/templates/sezione-senza-anticipazioni.template.en.jsx" /* webpackChunkName: "component---src-templates-sezione-senza-anticipazioni-template-en-jsx" */),
  "component---src-templates-sezione-senza-anticipazioni-template-it-jsx": () => import("./../../src/templates/sezione-senza-anticipazioni.template.it.jsx" /* webpackChunkName: "component---src-templates-sezione-senza-anticipazioni-template-it-jsx" */)
}

